.sec-title {
    position: relative;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 1.5em;
    color: #40cbb4;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
}


.sec-title h2 {
    position: relative;
    display: inline-block;
    font-size: 48px;
    line-height: 1.2em;
    color: #1e1f36;
    font-weight: 700;
}



.cards {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.pricing-section .row {
    margin: 0 -30px;
}

.pricing-block {
    width: 400px;
    position: relative;
    padding: 0 10px;
    margin-bottom: 40px;
    min-height: 700px; /* Fixed height */
    height: fit-content;
  }
  
  .pricing-block .inner-box {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 0 0 30px;
    max-width: 370px;
    margin: 0 auto;
    border-bottom: 20px solid #40cbb4;
    height: 100%; /* Ensure it fills the parent */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  

.pricing-block .icon-box {
    position: relative;
    padding: 50px 30px 0;
    background-color: #40cbb4;
    text-align: center;
}

.pricing-block .icon-box:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    border-radius: 50% 50% 0 0;
    background-color: #ffffff;
    content: "";
}


.pricing-block .icon-box .icon-outer {
    position: relative;
    height: 150px;
    width: 150px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    padding: 10px;
}

.pricing-block .icon-box i {
    position: relative;
    display: block;
    height: 130px;
    width: 130px;
    line-height: 120px;
    border: 5px solid #40cbb4;
    border-radius: 50%;
    font-size: 50px;
    color: #40cbb4;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
    transform: rotate(360deg);
}

.pricing-block .price-box {
    position: relative;
    text-align: center;
    padding: 10px 20px;
}

.pricing-block .title {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 600;
}

.pricing-block .price {
    display: block;
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    color: #40cbb4;
}


.pricing-block .features {
    position: relative;
    max-width: 200px;
    margin: 0 auto 20px;
    word-wrap: normal;
  }

.pricing-block .features li {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #848484;
    font-weight: 500;
    padding: 5px 0;
    padding-left: 30px;
    border-bottom: 1px dashed #dddddd;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
  .pricing-block .features li:hover {
    white-space: normal;
    overflow: visible;
    background-color: rgba(255, 255, 255, 0.9); /* Optional: to make the overflow text more readable */
    z-index: 1; /* Bring the hovered text to the front */
  }

  .pricing-block .features li:before {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    margin-top: -8px;
  }


  .pricing-block .features li.false:before {
    color: #e1137b;
    content: "\f057"; /* Cross icon */
  }


.pricing-block .features li.true:before {
    color: #2bd40f;
    content: "\f058"; /* Check icon */
  }

.pricing-block .features li a {
    color: #848484;
}

.pricing-block .features li:last-child {
    border-bottom: 0;
}

.pricing-block .btn-box {
    position: relative;
    text-align: center;
}

.pricing-block .btn-box a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    font-weight: 500;
    padding: 8px 30px;
    background-color: #40cbb4;
    border-radius: 10px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 300ms ease;
}

.pricing-block .btn-box a:hover {
    color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
    color: #40cbb4;
    background: none;
    border-radius: 0px;
    border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
    background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
    color: #1d95d2;
    background: none;
    border-radius: 0px;
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price {
    color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
    border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
    background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price {
    color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
    color: #ffc20b;
    background: none;
    border-radius: 0px;
    border-color: #ffc20b;
}

.theme-btn {
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    font-weight: 500;
    padding: 8px 30px;
    margin: 5px 0;
    background-color: #40cbb4;
    border-radius: 10px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: all 300ms ease;
  }
  
  .theme-btn:hover {
    color: #40cbb4;
    background: none;
    border-color: #40cbb4;
  }
  